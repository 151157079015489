import React, { useState, useEffect } from 'react';

// @ts-ignore
import { ReactComponent as LogoDM } from "assets/images/auth/logoDM.svg";

// @ts-ignore
import { ReactComponent as LogoPP } from "assets/images/auth/logoPP.svg?v=1";

// @ts-ignore
import { ReactComponent as LogoHC } from "assets/images/auth/logoHC.svg";

// @ts-ignore
import { ReactComponent as LogoGPS } from "assets/images/auth/logoGPS.svg";

// @ts-ignore
import { ReactComponent as LogoAPM } from "assets/images/auth/logoAPM.svg";

// @ts-ignore
import * as Layout from '../styled';

import * as Styled from './styled';

// @ts-ignore
import { useAllocatorAccess } from 'utils/utility';

const increaseBy = (num: number) => {
  let counter = num;
  return () => {
    const result = counter;
    counter += 50;
    return result;
  }
}

const getPath = () => {
  const location = window.location.href;

  // Array of staging urls
  const validStageURLs = [
    'compensation-tool-3752-staging.botics.co',
  ];

  const validDevelopURLs = [
    'dev.auth.arootah.com',
    'localhost',
    '127.0.0.1'
  ];

  if (validStageURLs.some(url => location.includes(url))) {
    return {
      ppURL: 'project-planner-36424-staging.botics.co',
      hcURL: 'hat-arootah-web-24408-staging.botics.co',
      gpsURL: 'goal-setting-workbo-37-staging.botics.co'
    }
  }
  else if (process.env.NODE_ENV === 'development' || validDevelopURLs.some(url => location.includes(url))) {
    return {
      ppURL: 'dev.pp.arootah.com',
      hcURL: 'dev.hat.arootah.com',
      gpsURL: 'dev.gm.arootah.com'
    }
  }

  return {
    ppURL: 'apps.arootah.com',
    hcURL: 'app.arootah.com',
    gpsURL: 'goals.arootah.com'
  }
}

const Landing = () => {
  const animateDelayCounter = increaseBy(100);

  const [animate, setAnimate] = useState(false);

  const isAllocatorUser = useAllocatorAccess();

  useEffect(() => {
    setTimeout(() => {
      setAnimate(true);
    }, 100);
  }, []);

  const { ppURL, hcURL, gpsURL } = getPath();

  return (
    <Layout.Screen $landing>
      <Styled.FormWrapper>
        <Layout.Title $animate={animate} $animateDelay={animateDelayCounter()} data-testid="welcome">Welcome to Arootah Apps!</Layout.Title>
        <Styled.Text $animate={animate} $animateDelay={animateDelayCounter()}>Choose the app where you want to log in.</Styled.Text>

        <Styled.AppsList>
          <Styled.AppLink href={`https://${gpsURL}`} target="_blank" $animate={animate} data-testid="gps" $animateDelay={animateDelayCounter()}>
            <LogoGPS />
            <Styled.AppItemTitle>Goal Manager</Styled.AppItemTitle>
          </Styled.AppLink>

          <Styled.AppLink href={`https://${gpsURL}/time-manager`} target="_blank" $animate={animate} data-testid="apm" $animateDelay={animateDelayCounter()}>
            <LogoAPM />
            <Styled.AppItemTitle>Time Manager</Styled.AppItemTitle>
          </Styled.AppLink>

          <Styled.AppLink href={`https://${hcURL}`} target="_blank" $animate={animate} data-testid="hc" $animateDelay={animateDelayCounter()}>
            <LogoHC />
            <Styled.AppItemTitle>Habit Manager</Styled.AppItemTitle>
          </Styled.AppLink>

          <Styled.AppLink href={`https://${ppURL}/${isAllocatorUser ? "allocator-tool" : "decision-manager"}`} target="_blank" data-testid="dm" $animate={animate} $animateDelay={animateDelayCounter()}>
            <LogoDM />
            <Styled.AppItemTitle>{isAllocatorUser ? "Allocator Tool" : "Decision Manager"}</Styled.AppItemTitle>
          </Styled.AppLink>

          <Styled.AppLink href={`https://${ppURL}/project-planner`} target="_blank" data-testid="pp" $animate={animate} $animateDelay={animateDelayCounter()}>
            <LogoPP />
            <Styled.AppItemTitle>Project Manager</Styled.AppItemTitle>
          </Styled.AppLink>
        </Styled.AppsList>
      </Styled.FormWrapper>
    </Layout.Screen>
  );
}

export default Landing;
